const axios = require("axios").default;

function logVisitingBlog() {
  const isLogged = window.sessionStorage.getItem("isLogged");
  if (isLogged) return;
  window.sessionStorage.setItem("isLogged", true);

  return apiSubmit("post", `api/Logs/visitBlogPage`);
}

function apiSubmit(requestType, url, data = null) {
  return new Promise((resolve, reject) => {
    // If user is offline, try to get response from APIStorage
    if (!window.navigator.onLine) {
      console.error("No internet connection");
    }

    const endpoint = `https://loremaster-prod.azurewebsites.net/${url}`;

    axios[requestType](endpoint, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

logVisitingBlog();
