require("normalize.css/normalize.css");
require("./style/index.css");
require("./utils/includeHTML");

// manifest
require("./assets/site.webmanifest");

// icons
require("./assets/icons/favicon.ico");
require("./assets/icons/android-chrome-192x192.png");
require("./assets/icons/android-chrome-512x512.png");
require("./assets/icons/apple-touch-icon.png");
require("./assets/icons/favicon-16x16.png");
require("./assets/icons/favicon-32x32.png");

// avatars
require("./assets/avatars/andrei-kniazev.jpg");

// posts images
require("./assets/images/cookie.png");
require("./assets/images/1-2020-year-summary.png");
require("./assets/images/2-excel.png");
require("./assets/images/2-first-timeline.png");
require("./assets/images/2-second-timeline.png");
require("./assets/images/2-third-timeline.png");
require("./assets/images/2-timeline.gif");
require("./assets/images/2-custom-calendar.png");

// logger
require("./utils/logVisitingBlog");

class Foo {
  constructor() {
    this.value = "foobar";
  }
  static instance() {
    return new Foo();
  }
  getValue() {
    return this.value;
  }
}

console.log(Foo.instance().getValue());
